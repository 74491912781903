/* this file is mostly a lie, used only for a little fuckery with page sizes */

@media print {
    @page {
        size: letter;
        margin: 0.25in 0;
    }

    html {
        width: 100%;
        height: 100%;
    }

    body {
        grid-template-columns: repeat(12,8.5in);
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    h1, h2, h3, h4, h5, h6
    {
        //white-space: nowrap;
        display: block;
        page-break-after:avoid;
        page-break-inside:avoid;
        break-inside: avoid;
    }
}